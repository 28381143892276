
  import React from 'react';
  import { getWidgetWrapper } from '@wix/yoshi-flow-editor/runtime/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/work/e47ef567209736c/packages/bookings-calendar-widget/src/components/BookingCalendar/Widget/index.tsx';

  import {
    withStyles
  } from '@wix/native-components-infra';

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  
import { createExperiments, createWidgetExperiments } from '@wix/yoshi-flow-editor/runtime/esm/experiments';
    

  
import { I18nextProvider } from '@wix/yoshi-flow-editor/i18n';


      import { PureExperimentsProvider } from '@wix/yoshi-flow-editor';
      var ExperimentsProvider = React.Fragment;
      

import { BILoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/BILogger/BILoggerProvider';

import { FedopsLoggerProvider } from '@wix/yoshi-flow-editor/runtime/esm/react/FedopsLoggerProvider';

import { HttpProvider } from '@wix/yoshi-flow-editor';

import { TPAComponentsProvider } from 'wix-ui-tpa';

import { BaseUiEnvironmentProviderWrapper } from '@wix/yoshi-flow-editor/runtime/esm/react/BaseUiEnvironmentProviderWrapper';

var providers = {
  I18nextProvider,
  ExperimentsProvider,
  PureExperimentsProvider,
  BILoggerProvider,
  FedopsLoggerProvider,
  HttpProvider,
  TPAComponentsProvider,
  BaseUiEnvironmentProviderWrapper,
}


  

    import * as usersStyleParamsEntry from '/home/builduser/work/e47ef567209736c/packages/bookings-calendar-widget/src/components/BookingCalendar/stylesParams.ts';
var stylesParamsEntry = usersStyleParamsEntry;
var stylesParams = stylesParamsEntry.default;
var customCssVars = stylesParamsEntry.customCssVars || function() { return {} };
    


  var styleHocConfig = {"enabled":true};

  var sentryConfig = {
      DSN: 'https://5c05b49c51a145fb801c55a588742af8@sentry.wixpress.com/1120',
      id: '5c05b49c51a145fb801c55a588742af8',
      projectName: 'bookings-calendar-widget',
      teamName: 'bookings',
      errorMonitor: true,
    };

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/e47ef567209736c/packages/bookings-calendar-widget/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var UserComponent = getWidgetWrapper({
      initI18n,
      withStylesHoc: withStyles,
      createExperiments,
      createWidgetExperiments,
      providers,
    }, Widget, {
    multilingualDisabled,
    sentryConfig,
    styleHocConfig,
    translationsConfig,
    stylesParams,
    customCssVars,
    componentId: '54d912c5-52cb-4657-b8fa-e1a4cda8ed01',
    name: 'BookingCalendar',
    withErrorBoundary: false,
    localeDistPath: 'assets/locales',
  });

  
    import { hot } from '@wix/component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    
    component: UserComponent,
    loadChunks
  };
